import { useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./header";

function MainLayout() {
    return (
        <>
            <Header />
            <div className="container">
                <Outlet />
            </div>
        </>
    );
}

export default MainLayout;
