import { useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";
import { BrowserProvider, Eip1193Provider, ethers } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { useInterval } from "react-use";
import { toast } from "react-toastify";
import { NumericFormat } from 'react-number-format';
import styled from 'styled-components';

export const RPC_URL = 'https://rpc.ankr.com/eth'
export const CHAIN_ID = 1
const PRESALE_ADDRESS = '0x1edB713FC5bF8C64D21411F72b129B21111124E2'
const START_TIME = new Date('2024-10-08T07:00:00Z').getTime()
const DURATION_BONUS = 3600 * 2 * 1000
const DURATION = 3600 * 45 * 1000
const SOFT_CAP = 69
const HARD_CAP = 420

enum ROUND {
    NONE, SOFT_SALE, HARD_SALE, FINISHED
}

const Progress = styled.span<{ value: number }>`
    &::before {
        content: '';
        background: red;
        width: ${({ value }) => value ?? 0}%;
    }
    &::after {
        position: absolute;
        content: '${({ value }) => Math.min(value, 100)?.toFixed(1) ?? 0}%';
        background: transparent;
        color: white;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.8em;
    }
`

const Dashboard = () => {
    const { address, isConnected, chainId } = useWeb3ModalAccount()
    const { open } = useWeb3Modal()
    const [round, setRound] = useState(ROUND.NONE)
    const [timeMode, setTimeMode] = useState('')
    const [timeLeft, setTimeLeft] = useState('00:00:00')
    const [ethAmount, setEthAmount] = useState('')
    const [tokenAmount, setTokenAmount] = useState('')
    const [ethAccountBalance, setEthAccountBalance] = useState(0n)
    const [ethTotalBalance, setEthTotalBalance] = useState(0n)
    const { walletProvider } = useWeb3ModalProvider()

    const provider = new ethers.JsonRpcProvider(RPC_URL)

    useEffect(() => {
        if (address) {
            (async () => {
                setEthAccountBalance(await provider.getBalance(address!))
            })()
        }
    }, [provider, address])

    useInterval(async () => {
        if(round === ROUND.FINISHED) {
            setTimeMode('')
            setTimeLeft('Finished')
            return
        }
        const now = Math.floor(Date.now())
        const tmStart = Number(START_TIME)
        const tmBonusEnd = Number(START_TIME + DURATION_BONUS)
        const tmEnd = Number(START_TIME + DURATION)
        if (now >= tmEnd) {
            setRound(ROUND.FINISHED)
            setTimeMode('')
            setTimeLeft('Finished')
        } else {
            if (now < tmStart - 3600 * 1000) {
                setTimeMode('')
                setTimeLeft("Tue, Oct 8th 3pm EST")
            } else if (now < tmStart) {
                setTimeMode('Starts in')
                const left = Math.floor((tmStart - now) / 1000)
                const hours = Math.floor(left / 3600)
                const mins = Math.floor(left / 60 % 60)
                const secs = Math.floor(left % 60)
                setTimeLeft(`${String(hours).padStart(2, '0')}h : ${String(mins).padStart(2, '0')}m : ${String(secs).padStart(2, '0')}s`)
            } else {
                setTimeMode('Ends in')
                if (round <= ROUND.SOFT_SALE && now < tmBonusEnd)
                    setRound(ROUND.SOFT_SALE)
                else
                    setRound(ROUND.HARD_SALE)
                const left = Math.floor(((round===ROUND.SOFT_SALE ? tmBonusEnd : tmEnd) - now) / 1000)
                const hours = Math.floor(left / 3600)
                const mins = Math.floor(left / 60 % 60)
                const secs = Math.floor(left % 60)
                setTimeLeft(`${String(hours).padStart(2, '0')}h : ${String(mins).padStart(2, '0')}m : ${String(secs).padStart(2, '0')}s`)
            }
        }
    }, 300)

    useInterval(async () => {
        setEthTotalBalance(await provider.getBalance(PRESALE_ADDRESS))
    }, 1000)

    useEffect(() => {
        if(round===ROUND.SOFT_SALE && ethTotalBalance >= ethers.parseEther(String(SOFT_CAP))) {
            setRound(ROUND.HARD_SALE)
        } else if(round===ROUND.HARD_SALE && ethTotalBalance >= ethers.parseEther(String(HARD_CAP))) {
            setRound(ROUND.FINISHED)
        }
    }, [round, ethTotalBalance])

    const handleContribute = useCallback(async () => {
        if (walletProvider) {
            try {
                const ethersProvider = new BrowserProvider(walletProvider as Eip1193Provider)
                const signer = await ethersProvider.getSigner()
                if (chainId != CHAIN_ID) {
                    throw Error("Unsupported network")
                }
                if (!ethAmount)
                    throw Error("Input ETH amount")
                const amount = ethers.parseEther(ethAmount ?? '0')
                if (amount > ethAccountBalance)
                    throw Error("Exceed balance")
                await signer.sendTransaction({ to: PRESALE_ADDRESS, value: amount })
                setEthAccountBalance(await provider.getBalance(address!))
                setEthTotalBalance(await provider.getBalance(PRESALE_ADDRESS))
                setEthAmount('')
                toast.success("Successfuly contributed!")
            } catch (ex: any) {
                console.log(ex)
                const errorMessage = ex?.shortMessage || ex?.message || "Error in airdropping token";
                toast.error(errorMessage)
            }
        }
    }, [walletProvider, ethAmount, ethAccountBalance, chainId])

    return <>
        <div className="home section">
            <span id="section-home" />
            <div className="faces">
                <div className="faceRow only-desktop">
                    <img src="images/face-02.PNG" alt="face-02" />
                    <img src="images/face-01.PNG" alt="face-01" />
                    <img src="images/face-03.PNG" alt="face-03" />
                </div>
                <div className="faceRow">
                    <img src="images/face-07.PNG" alt="face-07" />
                    <img src="images/face-00.png" alt="face-00" />
                    <img src="images/face-04.PNG" alt="face-04" />
                </div>
                <div className="faceRow only-desktop">
                    <img src="images/face-05.PNG" alt="face-05" />
                    <img src="images/face-06.PNG" alt="face-06" />
                    <img src="images/face-08.PNG" alt="face-08" />
                </div>
            </div>
            <div className="console">
                <img id="flag" src="images/flag.GIF" alt="flag" />
                <div className="consoleLayer">
                    <div className="consoleTop">
                    </div>
                    <div className="consoleCenter">
                        <div className="top">
                            <div className="progressBar">
                                <Progress value={ Number(ethers.formatEther(ethTotalBalance)) * 100 / (round <= ROUND.SOFT_SALE ? SOFT_CAP : HARD_CAP)  } />
                            </div>
                        </div>
                        <img src="images/consoleBottom.png" alt="console-top" />
                        <div className="center">
                            <h2>THE OFFICIAL SEQUEL TO</h2>
                            <h2>DOLAND TREMP IS HERE</h2>
                            <h3>$REPOOBLICAN</h3>
                            <p>CA: ADPBVJj1cuMmcxUdgES8B93BgbK852nptL3PbgzFN3CG</p>
                        </div>
                        <img src="images/consoleCenter.png" alt="console" />
                    </div>
                    <div className="consoleBottom">
                        <img src="images/consoleBottom.png" alt="console" />
                    </div>
                </div>
                <img id="flag" src="images/flag.GIF" alt="flag" />
            </div>
        </div>
        <div className="section About">
            <span id="section-about" />
            <div>
                <h2>ABOUT</h2>
                <p>The Repooblican coin is the next extension of Doland Tremp and is here for the politifi season and we're bringing back the magic! <br />The Repooblican party will provide long term success with buy back and burns, political donations, consistent marketing, big callers, T1 KOLs and T1 CEX listings. If you thought Tremp was big then Repooblican is going to be YUUUUUGE.</p>
            </div>
            <img id="people" src="images/people.GIF" alt="people" />
            <img src="images/about-bg.png" alt="about-bg" />
        </div>
        <div className="section Whitehouse">
            <span id="section-whitehouse" />
            <div className="flag-container">
                <div className="flag"><img src="images/flag-1.png" alt="Flag" /></div>
                <div className="flag"><img src="images/flag-2.png" alt="Flag" /></div>
                <div className="flag"><img src="images/flag-3.png" alt="Flag" /></div>
                <div className="flag"><img src="images/flag-4.png" alt="Flag" /></div>
                <div className="flag"><img src="images/flag-5.png" alt="Flag" /></div>
                <div className="flag"><img src="images/flag-6.png" alt="Flag" /></div>
                <div className="flag"><img src="images/flag-7.png" alt="Flag" /></div>
                <div className="flag"><img src="images/flag-8.png" alt="Flag" /></div>
                <div className="flag"><img src="images/flag-9.png" alt="Flag" /></div>
                <div className="flag"><img src="images/flag-10.png" alt="Flag" /></div>
            </div>
            <div className="memes">
                <img src="images/memes0.GIF" alt="memes.gif" />
                <img src="images/memes1.GIF" alt="memes.gif" />
            </div>
            <img id="bgFlags" src="images/USA-flag.PNG" alt="USA-flag" />
        </div>
        <div className="mobile-contract only-mobile">
            <div className="mobile-contract-inner">
                <p>CA: ADPBVJj1cuMmcxUdgES8B93BgbK852nptL3PbgzFN3CG</p>
            </div>
        </div>
    </>
}

export default Dashboard