import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers/react"
import { CHAIN_ID } from "../views/Dashboard"
import { useState } from "react"

export default function Header() {
    const { isConnected, chainId } = useWeb3ModalAccount()
    const { open } = useWeb3Modal()
    const [menu, showMenu] = useState(false)

    return <>
        <nav>
            <div className="logo">
                <img src="images/logo.PNG" alt="logo" />
            </div>
            <ul className="social-links">
                <li>
                    <a href="https://x.com/repooblican" target="_blank">
                        <img src="images/twitter.png" alt="twitter" />
                    </a>
                </li>
                <li>
                    <a href="https://t.me/repooblican" target="_blank">
                        <img src="images/telegram.png" alt="telegram" />
                    </a>
                </li>
                <li>
                    <a href="https://www.dexscreener.com" target="_blank">
                        <img src="images/dexscreener.png" alt="Dexscreener" />
                    </a>
                </li>
                <li>
                    <a href="https://www.dextools.io/app/en/hot-pairs" target="_blank">
                        <img src="images/dextools.png" alt="Dextools" />
                    </a>
                </li>
            </ul>
        </nav>
    </>
}