import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainLayout from './components/layout';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import './App.css';
import Dashboard, { CHAIN_ID, RPC_URL } from './views/Dashboard';

const projectId = '07efe273ec05006951c30c4399c2e9b9';

const ethereum = {
	chainId: CHAIN_ID,
	name: 'Ethereum',
	currency: 'ETH',
	explorerUrl: 'https://etherscan.io',
	rpcUrl: RPC_URL,
}

const metadata = {
	name: 'Presale',
	description: 'Presale token',
	url: '...', // origin must match your domain & subdomain
	icons: []
};

const ethersConfig = defaultConfig({
	metadata,
	enableEIP6963: true, // true by default
	enableInjected: false, // true by default
	enableCoinbase: false, // true by default
	rpcUrl: RPC_URL, // used for the Coinbase SDK
	defaultChainId: CHAIN_ID, // used for the Coinbase SDK
});

createWeb3Modal({
	ethersConfig,
	chains: [ethereum],
	// chainImages: {
	//   1: ethereumLogo
	// },
	// connectorImages: {
	//   coinbaseWallet: coinbaseLogo,
	//   walletConnect: walletConnectLogo,
	// },
	projectId,
	// allWallets: 'HIDE'
	// enableAnalytics: true, // Optional - defaults to your Cloud configuration
	themeMode: 'light',
	// themeVariables: {
	// 	'--w3m-color-mix': 'red',
	// 	'--w3m-accent': '',
	// 	'--w3m-border-radius-master': '12px',
	// 	'--w3m-color-mix-strength': 1,
	// 	'--w3m-font-family': 'GothamPro',
	// }
});

function App() {
	return (
		<div className="App">
			<ToastContainer />
			<BrowserRouter>
				<Routes>
					<Route element={<MainLayout />}>
						<Route path='/' element={<Dashboard />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;